<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error obteniendo los datos de la orden
      </h4>
      <div class="alert-body">
        No se ha encontrado una orden con el ID seleccionado. Ingresa a la
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          lista de ordenes
        </b-link>
        para seleccionar otra.
      </div>
    </b-alert>

    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mb-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper mb-0">
                  <logo />
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title mt-1 mb-2">
                  Resumen de la cotización
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title invoice-date-title-2">
                    Fecha de cotización
                  </p>
                  <p class="invoice-date">
                    {{ formatDate(invoiceData.created_at) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="invoiceData.items"
            :fields="['producto', 'cantidad', 'precio', 'total']"
          >
            <template #cell(producto)="data">
              <b-card-text class="font-weight-bold mb-25">
                <a href="">{{ data.item.product.name }}</a>
              </b-card-text>
            </template>
            <template #cell(cantidad)="data">
              <b-card-text>
                {{ data.item.quantity }}
              </b-card-text>
            </template>
            <template #cell(precio)="data">
              <b-card-text>
                {{ formatter.format(data.item.price) }}
              </b-card-text>
            </template>
            <template #cell(total)="data">
              <b-card-text>
                {{ formatter.format(data.item.quantity * data.item.price) }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              />

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Envío:
                    </p>
                    <p class="invoice-total-amount">
                      ${{ shipments }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total (sin IVA):
                    </p>
                    <p class="invoice-total-amount">
                      {{ formatter.format(parseFloat(Number.isInteger(invoiceData.shipping) ? invoiceData.shipping : invoiceData.shipping.replace(/,/, '')) + parseFloat(invoiceData.total.replace(/,/, ''))) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">
        </b-card>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimir cotización
          </b-button>
        </b-card>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import store from '@/store'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BAlert, BLink, VBToggle, BButton,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/LogoBlack.vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BAlert,
    BLink,
    Logo,
  },
  data() {
    return {
      issueInvoiceToggle: false,
      submitting: false,
    }
  },
  computed: {
    formatter() {
      return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
      })
    },
    total() {
      return parseFloat(this.invoiceData.total) + parseFloat(this.shipments)
    },
  },
  setup() {
    const invoiceData = ref(null)
    const shipments = ref([])

    const printInvoice = () => {
      window.print()
    }

    const fetchCartProducts = () => {
      store.dispatch('products/indexCart').then(
        response => {
          invoiceData.value = response.data
          shipments.value = response.data.shipping
        },
        error => {
          if (error.response.status === 404) {
            invoiceData.value = undefined
          }
        },
      )
    }
    fetchCartProducts()

    return {
      invoiceData,
      shipments,
      printInvoice,
      fetchCartProducts,
    }
  },
  methods: {
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'America/Mexico_City',
      }
      const parsedDate = new Date(date)
      const formatter = new Intl.DateTimeFormat('es', options)
      return formatter.format(parsedDate)
    },
    getOrderInformation() {
      store.dispatch('products/indexCart').then(
        response => {
          this.invoiceData.value = response.data
          this.shipments.value = response.data.shipping
        },
        error => {
          if (error.response.status === 404) {
            this.invoiceData.value = undefined
          }
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.invoice-date-wrapper {
  .invoice-date-title {
    min-width: 11rem!important;
  }
}
.invoice-title {
  font-size: 1.6rem!important;
}
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
   .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
